import { graphql } from "gatsby";
import { Helmet, Link, Trans } from "gatsby-plugin-react-i18next";
import React, { useState } from "react";
import styled from "styled-components";
import Layout from "../../../components/nigeria/layout";
import { Nigeria_MetaTags } from "../../../helpers/constants";
import {
  ArrowRightShortIcon,
  BellIcon,
  BriefCaseIcon,
  CardIcon,
  CircularProgress,
  CollapseMenuIcon,
  DownloadSliderIcon,
  EarnIcon,
  ExpandMenuIcon,
  HandMoneyIcon,
  LoansIcon,
  PhoneIcon,
  PosIcon,
  StarBellIcon,
} from "../../../images/nigeria/Icons";

import HeroImage from "../../../images/nigeria/personal/game/bbn-bg.png";
import HeroBadges from "../../../images/nigeria/personal/game/bbn-game-hero.png";

import EarnEasyIcon from "../../../images/nigeria/personal/game/bbn-earn-easy.svg";
import PlayGameIcon from "../../../images/nigeria/personal/game/bbn-play-game.svg";
import RaiseTopIcon from "../../../images/nigeria/personal/game/raise-to-top.svg";

import AppStoreImage from "../../../images/nigeria/footer/app-store.svg";
import PlayStoreImage from "../../../images/nigeria/footer/google-play.svg";

import AppStoreImageMobile from "../../../images/nigeria/personal/index/app-store-mobile.svg";
import PlayStoreImageMobile from "../../../images/nigeria/personal/index/play-store-mobile.svg";

import BbnGameVector from "../../../images/nigeria/personal/game/bbn-vector.png";
import HeroImageMobileDownload from "../../../images/nigeria/personal/index/index-hero-mobile.png";
import HeroImageDownload from "../../../images/nigeria/personal/index/yellow-background.png";

import { StaticImage } from "gatsby-plugin-image";
import useScrollPosition from "../../../hooks/useScrollPosition";
import MiddleHeaderImage from "../../../images/nigeria/personal-banking/middle-header.png";

const BbnGame = ({ data }) => {
  const scrollRef = useScrollPosition();
  const faqs = [
    {
      title: "How do I join the game?",
      desc: "All you need to do is Download the Moniepoint Personal Banking App and sign up.",
    },
    {
      title: "How many tasks am I allowed to complete?",
      desc: "As many as possible. The more tasks you perform, the more coins you get.",
    },
    {
      title: "Do I have to pay to join the game?",
      desc: "No. Participation is completely free.",
    },
    {
      title: "How do I know if I’ve won a prize?",
      desc: "You’ll receive a notification instantly",
    },
    ,
  ];
  const sliderItems = [
    {
      title: "Qualify",
      image: "./../../images/nigeria/personal/game/bbn-slide-1.png",
      cta: 'Download the App',
      items: [
        {
          icon: <DownloadSliderIcon />,
          desc: "Download the Moniepoint Personal Banking App",
        },
        {
          icon: <EarnIcon />,
          desc: (
            <div className={"d-flex gap-3 flex-column"}>
              <div>
                Earn coins by completing these tasks to activate your account;
              </div>
              <ul
                className={
                  "list-items gap-1 px-2 text-start d-flex flex-column"
                }
              >
                <li>- Create an account</li>
                <li>- Upgrade your account to level 2</li>
                <li>- Fund your account</li>
                <li>- Request a card</li>
                <li>- Performing transactions, and more</li>
              </ul>
            </div>
          ),
        },
        {
          icon: <DownloadSliderIcon />,
          desc: (
            <Trans>
              You get notified via push notification for every task completed
              and the number of coins earned.
            </Trans>
          ),
        },
      ],
    },
    {
      title: "Play!",
      image: "./../../images/nigeria/personal/game/bbn-slide-2.png",
      cta: 'Get your Account',
      items: [
        {
          icon: <BriefCaseIcon />,
          desc: (
            <Trans>
              Every week, a new game will be created and made available for you
              to play within the app.
            </Trans>
          ),
        },
        {
          icon: <StarBellIcon />,
          desc: (
            <Trans>
              With the coins earned from performing tasks, you can play the game of the week.
            </Trans>
          ),
        },
        {
          icon: <HandMoneyIcon />,
          desc: (
            <Trans>
              As you play and win, your name gets ranked on the national
              leaderboard!
            </Trans>
          ),
        },
      ],
    },
    {
      title: "Win",
      image: "./../../images/nigeria/personal/game/bbn-slide-3.png",
      cta: 'Start playing',
      items: [
        {
          icon: <BellIcon />,
          desc: <Trans>You’ll get notified every time you win a prize.</Trans>,
        },
        {
          icon: <StarBellIcon />,
          desc: (
            <Trans>
              Over 400,000 people will each win cash or airtime INSTANTLY!
            </Trans>
          ),
        },
        {
          icon: <HandMoneyIcon />,
          desc: (
            <Trans>12 people will win grand prizes totalling 200M naira!</Trans>
          ),
        },
        {
          icon: <HandMoneyIcon />,
          desc: (
            <Trans>
              At the end of the show, the person with the most coins will finish on top of the leaderboard and get a N10m grand prize!
            </Trans>
          ),
        },
      ],
    },
  ];

  const footerContent = {
    title: "Climb the leaderboard, and win the ultimate prize!",
    cta: "Get started",
    link: "https://app.adjust.com/13owp4gc",
    bgColor: "rgba(3, 87, 238, 1)",
    options: { target: '_blank' }
  };

  return (
    <Layout
      pageTitle={Nigeria_MetaTags.personalBanking.bbnGame.title}
      description={Nigeria_MetaTags.personalBanking.bbnGame.description}
      bankingType={"personal"}
      footerContent={footerContent}
    >
      <Helmet>
        <link
          rel="alternate"
          href="https://moniepoint.com/ng/personal/bbn-game"
          hreflang="x-default"
        />
        <link
          rel="alternate"
          href="https://moniepoint.com/ng/personal/bbn-game"
          hreflang="en-ng"
        />
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "name": "Moniepoint Nigeria",
  "description": "We are a financial services company based in Nigeria, offering a range of business banking services including business accounts, business loans, pos terminal machines, expense cards,win big with big brother naija and more.",
  "image": "https://moniepoint.com/static/MFB-667f089014bfae5d706fc620b07be2e0.png",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "12, Wole Ariyo Street.",
    "addressLocality": "Lekki Phase 1",
    "addressRegion": "Lagos",
    "postalCode": "106104",
    "addressCountry": "NG"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": 6.44300,
    "longitude": 3.45908
  },
  "url": "https://moniepoint.com/ng/",
  "telephone": "+01 888 9990",
  "openingHoursSpecification": {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday"
    ],
    "opens": "08:00",
    "closes": "17:00"
  },
  "sameAs": [
    "https://www.facebook.com/Moniepointng/",
    "https://instagram.com/moniepointng",
    "https://twitter.com/moniepointng",
    "https://www.linkedin.com/showcase/moniepoint/"
  ]
}`}
        </script>
      </Helmet>

      <HeroContainer src={HeroImage}>
        <div className="hero-content-wrapper">
          <div className="hero-content">
            <h1 className="hero-title">
              Over 200m to be won <br /> with Moniepoint <br /> this BBN season
            </h1>
            <p className="hero-subtitle">
              <Trans>
                Open a Moniepoint Personal Bank Account, and earn weekly while doing tasks. Don’t miss out.
              </Trans>
            </p>

            <div className={"d-flex flex-row gap-2 gap-md-4"}>
              <a
                href="https://app.adjust.com/13owp4gc"
                target="_blank"
                className="btn btn-primary"
              >
                <Trans>Play Now</Trans>
                <ArrowRightShortIcon size={24} />
              </a>
            </div>
          </div>
        </div>

        <div className="hero-image-container">
          <img
            alt="..."
            src={HeroBadges}
            className="hero-image"
          />
        </div>

      </HeroContainer>

      <EaseContainer src={MiddleHeaderImage}>
        <div className={"content"}>
          <h1 className="ease-heading">
            <Trans>Win big on the Moniepoint Personal Banking App</Trans>
          </h1>
          <div className="ease-content">
            <div className={"d-flex flex-row gap-4 align-items-center py-2"}>
              <span>
                <img src={EarnEasyIcon} />
              </span>
              <Trans>Earn coins by completing tasks</Trans>
            </div>
            <div className={"d-flex flex-row gap-4 align-items-center py-2"}>
              <span>
                <img src={PlayGameIcon} />
              </span>
              <Trans>
                Play the game of the week with your coins
              </Trans>
            </div>
            <div className={"d-flex flex-row gap-4 align-items-center py-2"}>
              <span>
                <img src={RaiseTopIcon} />
              </span>
              <Trans>Rise to the top of the leaderboard!</Trans>
            </div>
          </div>
        </div>
      </EaseContainer>

      <SliderMobile className={""}>
        <div className={"content"}>
          <div className={"d-flex gap-1 flex-column how-it-works-container"}>
            <span className={"how-it-works"}>How does it work? - Qualify, Play, Win!</span>
            <div className={"how-it-works-title"}>
              Compete to win the N10m  <br /> Grand Prize!
            </div>
          </div>
          {sliderItems.map((item, index) => (
            <div className={"d-flex flex-column"} key={index}>
              <div className={"title text-center"}>{item.title}</div>
              <div
                className={"image py-4 d-flex flex-row justify-content-center"}
              >
                <StaticImage
                  loading="eager"
                  className=""
                  src={"../../../images/nigeria/personal/game/bbn-slide-1.png"}
                />
              </div>
              <div>
                {item.items.map((list, i) => (
                  <div>
                    <div key={i} className={"d-flex flex-row gap-1"}>
                      <div>{list.icon}</div>
                      <div>{list.desc}</div>
                    </div>
                    <div className={"feature-divider"}></div>
                  </div>
                ))}
              </div>
              <div className={"justify-content-center flex-row d-flex py-4"}>
                <a
                  href="https://app.adjust.com/13owp4gc"
                  target="_blank"
                  className="btn btn-primary"
                >
                  <Trans>Get Started Now</Trans>
                  <ArrowRightShortIcon size={24} />
                </a>
              </div>
            </div>
          ))}
        </div>
      </SliderMobile>

      <SwiperContainer ref={scrollRef}>
        <div className="sticky-container">
          <div className="top-content content">
            <div className={"d-flex gap-1 flex-column how-it-works-container"}>
              <span className={"how-it-works"}>How does it work? - Qualify, Play, Win!</span>
              <div className={"how-it-works-title"}>
                Compete to win the N10m  <br /> Grand Prize!
              </div>
            </div>
          </div>

          <div className="top-content d-none">
            <div className="timeline">
              <div className="timeline-item tl-1 active">
                <div className="progress-element">
                  <CircularProgress id={1} />
                  <PhoneIcon />
                </div>
                <div className="timeline-track">
                  <div className="timeline-track-item"></div>
                </div>
              </div>
              <div className="timeline-item tl-2">
                <div className="progress-element">
                  <CircularProgress id={2} />
                  <PosIcon size={32} />
                </div>
                <div className="timeline-track">
                  <div className="timeline-track-item"></div>
                </div>
              </div>
              <div className="timeline-item tl-3">
                <div className="progress-element">
                  <CircularProgress id={3} />
                  <CardIcon size={32} />
                </div>
                <div className="timeline-track">
                  <div className="timeline-track-item"></div>
                </div>
              </div>
              <div className="timeline-item tl-4 last">
                <div className="progress-element">
                  <CircularProgress id={4} />
                  <LoansIcon />
                </div>
              </div>
            </div>
          </div>
          <div className="timeline-body">
            <div className="timeline-body-item timeline-body-1">
              <div className="flex-container">
                <div className="text-section">
                  <h3 className="title">
                    <Trans>Qualify</Trans>
                  </h3>
                  <div className="features-list">
                    <div className="feature-item">
                      <DownloadSliderIcon />
                      <span>
                        <Trans>Download the Moniepoint Personal Banking App</Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <EarnIcon />
                      <span>
                        <Trans>
                          <div className={"d-flex gap-3 flex-column"}>
                            <div>
                              You get notified via push notification for every
                              task completed and the number of coins earned.
                            </div>
                            <ul
                              className={
                                "list-items gap-1 px-2 text-start d-flex flex-column"
                              }
                            >
                              <li>- Create an account</li>
                              <li>- Upgrade your account to level 2</li>
                              <li>- Fund your account</li>
                              <li>- Request a card</li>
                              <li>- Performing transactions, and more</li>
                            </ul>
                          </div>
                        </Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <BellIcon />
                      <span>
                        <Trans>
                          You get notified via push notification for every task
                          completed and the number of coins earned.
                        </Trans>
                      </span>
                    </div>
                  </div>
                  <a
                    href="https://app.adjust.com/13owp4gc"
                    target="_blank"
                    className="btn btn-primary"
                  >
                    <Trans>Get Started Now</Trans>
                    <ArrowRightShortIcon size={24} />
                  </a>
                </div>
                <div className="image-section">
                  <StaticImage
                    loading="eager"
                    className=""
                    src={
                      "../../../images/nigeria/personal/game/bbn-slide-1.png"
                    }
                  />
                </div>
              </div>
            </div>
            <div className="timeline-body-item timeline-body-2">
              <div className="flex-container">
                <div className="text-section">
                  <h3 className="title">
                    <Trans>Play!</Trans>
                  </h3>
                  <div className="features-list">
                    <div className="feature-item">
                      <BriefCaseIcon />
                      <span>
                        <Trans>
                          Every week, a new game will be created and made
                          available for you to play within the app.
                        </Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <StarBellIcon />
                      <span>
                        <Trans>
                          You can use the coins earned from performing tasks to
                          play the Moniepoint game of the week.
                        </Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <HandMoneyIcon />
                      <span>
                        <Trans>
                          As you play and win, your name gets ranked on the
                          national leaderboard!
                        </Trans>
                      </span>
                    </div>
                  </div>
                  <a
                    href="https://app.adjust.com/13owp4gc"
                    target="_blank"
                    className="btn btn-primary"
                  >
                    <Trans>Get Started Now</Trans>
                    <ArrowRightShortIcon size={24} />
                  </a>
                </div>
                <div className="image-section">
                  <StaticImage
                    loading="eager"
                    className=""
                    src={
                      "../../../images/nigeria/personal/game/bbn-slide-2.png"
                    }
                  />
                </div>
              </div>
            </div>
            <div className="timeline-body-item timeline-body-3">
              <div className="flex-container">
                <div className="text-section">
                  <h3 className="title">
                    <Trans>Win</Trans>
                  </h3>
                  <div className="features-list">
                    <div className="feature-item">
                      <BellIcon />
                      <span>
                        <Trans>
                          You’ll get notified every time they win a prize.
                        </Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <StarBellIcon />
                      <span>
                        <Trans>
                          Over 400,000 people will each win cash or airtime
                          INSTANTLY!
                        </Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <HandMoneyIcon />
                      <span>
                        <Trans>
                          12 people will win grand prizes totalling 200M naira!
                        </Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <HandMoneyIcon />
                      <span>
                        <Trans>
                          At the end of the TV show, person with the most coins
                          will finish top of the leaderboard and get a N10m
                          grand prize!
                        </Trans>
                      </span>
                    </div>
                  </div>
                  <a
                    href="https://app.adjust.com/13owp4gc"
                    target="_blank"
                    className="btn btn-primary"
                  >
                    <Trans>Get Started Now</Trans>
                    <ArrowRightShortIcon size={24} />
                  </a>
                </div>
                <div className="image-section">
                  <StaticImage
                    loading="eager"
                    className=""
                    src={
                      "../../../images/nigeria/personal/game/bbn-slide-3.png"
                    }
                  />
                </div>
              </div>
            </div>
            <div className="timeline-body-item timeline-body-4">
              <div className="flex-container">
                <div className="text-section">
                  <h3 className="title">
                    <Trans>Win</Trans>
                  </h3>
                  <div className="features-list">
                    <div className="feature-item">
                      <BellIcon />
                      <span>
                        <Trans>
                          You’ll get notified every time they win a prize.
                        </Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <StarBellIcon />
                      <span>
                        <Trans>
                          Over 400,000 people will each win cash or airtime
                          INSTANTLY!
                        </Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <HandMoneyIcon />
                      <span>
                        <Trans>
                          12 people will win grand prizes totalling 200M naira!
                        </Trans>
                      </span>
                    </div>
                    <div className="feature-divider"></div>
                    <div className="feature-item">
                      <HandMoneyIcon />
                      <span>
                        <Trans>
                          At the end of the TV show, person with the most coins
                          will finish top of the leaderboard and get a N10m
                          grand prize!
                        </Trans>
                      </span>
                    </div>
                  </div>
                  <a
                    href="https://app.adjust.com/13owp4gc"
                    target="_blank"
                    className="btn btn-primary"
                  >
                    <Trans>Get Started Now</Trans>
                    <ArrowRightShortIcon size={24} />
                  </a>
                </div>
                <div className="image-section">
                  <StaticImage
                    loading="eager"
                    className=""
                    src={
                      "../../../images/nigeria/personal/game/bbn-slide-3.png"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperContainer>

      <HeroContainerDownload
        src={HeroImageDownload}
        mobileSrc={HeroImageMobileDownload}
      >
        {/* <div className="hero-mask"></div> */}
        <div className="hero-content-wrapper">
          <div className="hero-container-image">
            <img src={BbnGameVector} alt="hero Card Mobile" />
          </div>

          <div className="hero-content">
            <h1 className="hero-title d-flex flex-row gap-2 gap-md-4 text-sm-center text-md-start ">
              <Trans>
                Play and win weekly alongside your favorite BBN housemates
              </Trans>
            </h1>
            <div
              className={
                "d-flex flex-row gap-2 gap-md-4 justify-content-sm-center  justify-content-md-start"
              }
            >
              <div>
                <Link to={"https://app.adjust.com/13owp4gc"} target={"_blank"}>
                  <img
                    src={PlayStoreImage}
                    alt={""}
                    className={"d-none d-sm-block"}
                  />
                  <img
                    src={PlayStoreImageMobile}
                    alt={""}
                    className={"d-block d-sm-none"}
                  />
                </Link>
              </div>
              <div>
                <Link to={"https://app.adjust.com/13owp4gc"} target={"_blank"}>
                  <img
                    src={AppStoreImage}
                    alt={""}
                    className={"d-none d-sm-block"}
                  />
                  <img
                    src={AppStoreImageMobile}
                    alt={""}
                    className={"d-block d-sm-none"}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-container-image">
          <img src={BbnGameVector} alt="hero Card Mobile" />
        </div>
        <MobileImageContainer src={HeroImageMobileDownload}>
          <div
            className={
              "content d-flex items-align-center justify-content-end hero-container-image-mobile"
            }
          >
            <img src={BbnGameVector} alt="hero Card Mobile" />
          </div>
        </MobileImageContainer>
      </HeroContainerDownload>

      <FAQsContainer>
        <div className="content">
          <h3 className="title">
            <Trans>pos_page.faqs_title</Trans>
          </h3>
          <div className="faqs-list">
            {faqs.map((item, index) => (
              <div key={index}>
                <FaqItem title={item.title} description={item.desc} />
                <div className="faq-divider"></div>
              </div>
            ))}
          </div>
        </div>
      </FAQsContainer>
    </Layout>
  );
};

export default BbnGame;

export const FaqItem = ({ title, description, open }) => {
  const [isOpen, setIsOpen] = useState(open || false);
  const toggleIsOpen = () => setIsOpen((value) => !value);
  return (
    <FAQItemConatainer>
      <div className="text-section">
        <div className="title" onClick={toggleIsOpen}>
          <Trans>{title}</Trans>
        </div>
        <div className={`description ${isOpen && "open"}`}>
          <Trans>{description}</Trans>
        </div>
      </div>
      <div className="toggle-button" onClick={toggleIsOpen}>
        {isOpen ? <CollapseMenuIcon /> : <ExpandMenuIcon />}
      </div>
    </FAQItemConatainer>
  );
};

const SwiperContainer = styled.section`
  background-color: white;
  height: calc(50vw * 5);

  .flex-container {
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
    padding: 0 0 78px 150px;
  }

  @media screen and (max-width: 1280px) {
    .flex-container {
      padding: 0 0 78px 60px;
    }
  }
  @media screen and (max-width: 1024px) {
    display: none;
  }

  .sticky-container {
    position: sticky;
    top: 77px;
    overflow-x: hidden;

    .top-content {
      position: relative;
      max-width: 1440px;
      margin: auto;
      padding: 32px 150px 32px;
      @media only screen and (max-width: 1280px) {
        padding: 64px 60px 32px;
      }

      @media screen and (max-height: 768px) {
        padding-top: 16px;
      }

      .how-it-works-title {
        color: #000;
        font-family: Founders Grotesk;
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
  
      .how-it-works {
        color: var(--colors-neutral-black-100, #000);
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%; /* 20.8px */
        padding: 10px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 40px;
        width: fit-content;
        background: var(--colors-primary-yellow-100, #fed75b);
      }

      .timeline {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .timeline-item {
          position: relative;
          width: 100%;
          display: inline-flex;
          align-items: center;

          &.last {
            width: auto;
          }

          .progress-element {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .progress-item {
              width: 64px;
              height: 64px;
              z-index: 1;
              transform: rotate(90deg);

              .track,
              .fill {
                fill: rgba(0, 0, 0, 0);
                stroke-width: 6;
                transform: rotate(90deg) translate(0px, -80px);
              }

              .track {
                stroke: rgba(11, 50, 117, 0.1);
              }

              .fill {
                stroke: rgba(3, 97, 240, 1);
                stroke-dasharray: 219.99078369140625;
                stroke-dashoffset: -219.99078369140625;
                transition: stroke-dashoffset 0.3s;
              }
            }

            svg:not(.progress-item) {
              position: absolute;

              path {
                fill: rgba(11, 50, 117, 0.1);
              }
            }
          }

          &.completed,
          &.active {
            svg:not(.progress-item) path {
              fill: rgba(3, 97, 240, 1);
            }
          }

          .timeline-track {
            width: 100%;
            height: 4px;
            background-color: rgba(11, 50, 117, 0.1);

            .timeline-track-item {
              height: 4px;
              width: 0px;
              background-color: rgba(3, 87, 238, 1);
              max-width: 100%;
              min-width: 0%;
              transition-delay: 0.3s;
              transition: width 0.3s ease-in-out;
            }
          }
        }
      }
    }
  }

  .timeline-body {
    max-width: 100vw;
    margin: auto;
    display: flex;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;

    .timeline-body-item {
      white-space: nowrap;
      min-width: 100vw;
      align-items: center;
      @media only screen and (max-width: 1024px) {
        padding: 0 60px;
      }

      transition: transform 0s ease-in-out;

      .flex-container {
        display: flex;
        justify-content: space-between;
        max-width: 1440px;
        margin: auto;
        gap: 20px;
        width: 100vw;
        @media screen and (max-height: 768px) {
          transform: scale(0.8);
          transform-origin: top center;
        }
      }

      .list-items {
        color: #555;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 18.2px */
        list-style: none;
      }

      .image-section {
        img {
          height: 100%;
          max-height: 735px;
          width: 100%;
        }
      }

      .text-section {
        max-width: 533px;
        padding-bottom: 16px;

        .title {
          font-family: "Founders Grotesk";
          font-size: 40px;
          font-weight: 500;
          line-height: 47px;
          letter-spacing: 0em;
          text-align: left;
          max-width: 533px;
          margin-bottom: 48px;
          white-space: break-spaces;
        }

        .features-list {
          display: flex;
          flex-direction: column;
          margin-bottom: 48px;
          max-width: 444px;

          .feature-item {
            display: flex;
            gap: 12px;
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
            //align-items: center;
            white-space: break-spaces;

            svg > path {
              fill: rgba(11, 50, 117, 0.5);
            }
          }

          .feature-divider {
            border: 0.8px solid rgba(3, 87, 238);
            background: rgba(3, 87, 238);
            margin: 28px 0;
            position: relative;
            opacity: 0.3;

            &:after {
              position: absolute;
              bottom: -3px;
              left: 0;
              height: 6px;
              width: 6px;
              background: rgba(3, 87, 238);
              content: "";
              border-radius: 3px;
            }

            &:after {
              right: -3px;
              left: auto;
            }
          }
        }
      }
    }
  }
`;

export const query = graphql`
  query {
    locales: allLocale(
      filter: { ns: { in: ["common", "nigeria"] }, language: { eq: "en" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    businessTips: allGraphCmsPost(
      sort: { fields: date, order: DESC }
      filter: { category: { categoryName: { eq: "Business Tips" } } }
    ) {
      nodes {
        slug
        title
        formattedDate
        coverImage {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;

const HeroContainer = styled.section`
  background: url(${(props) => props.src});
  background-size: cover;
  min-height: 497px;
  position: relative;
  overflow: clip;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column-reverse;
  }

  .hero-content-wrapper {
    height: 100%;
    max-width: 1440px;
    padding: 91px 150px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 99;
    position: relative;

    @media only screen and (max-width: 1280px) {
      padding: 91px 60px;
    }

    @media only screen and (max-width: 1024px) {
      padding: 48px 30px;
      flex-direction: column-reverse;
      margin: 0;
    }

    @media (max-width: 768px) {
      padding: 0 20px 48px 20px;
      height: auto;
      flex-direction: column-reverse;
      margin: 0;
    }
  }

  .hero-content {
    color: white;

    .hero-title {
      font-family: "Founders Grotesk";
      font-size: 58px;
      font-weight: 500;
      line-height: 66px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 16px;
      max-width: 652px;
      color: white;
    }

    .hero-subtitle {
      font-size: 17px;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
      max-width: 476px;
      font-family: inter;
      margin-bottom: 24px;
      color: white;
    }

    @media only screen and (max-width: 768px) {
      .hero-title {
        font-size: 40px;
        line-height: 44px;
      }

      .hero-subtitle {
        font-size: 16px;
        line-height: 25.76px;
      }
    }

    @media only screen and (max-width: 480px) {
      .hero-title {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 12px;
      }

      .hero-subtitle {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
      }

      .btn {
        padding: 8px 16px; /* Adjust padding for smaller screens */
        font-size: 14px;
      }
    }
  }

  .hero-image-container {
    mix-blend-mode: color-dodge;
    position: absolute;
    top: 50%;
    right: 4.5vw;
    transform: translateY(-50%);

    max-width: 692px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .hero-image {
      max-width:650px;
    }

    @media only screen and (max-width: 1024px) {
      position: relative;
      top: 0;
      right: 0;
      transform: none;
      margin: auto;

      .hero-image {
        width: inherit;
      }
    }
  }


`;
const MobileImageContainer = styled.section`
  @media only screen and (min-width: 1025px) {
    display: none;
  }
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
`;
const HeroContainerDownload = styled.section`
  background: url(${(props) => props.src});
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
  min-height: 497px;
  position: relative;
  @media (max-width: 1023px) {
    background: url(${(props) => props.mobileSrc});
    min-height: fit-content;
    background-size: 100% 94%;
    background-repeat: no-repeat;
  }

  .hero-video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }

  .hero-mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    z-index: 1;
  }

  .hero-content-wrapper {
    height: 100%;
    max-width: 1440px;
    padding: 113px 149px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: end;
    z-index: 99;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 140px 60px;
    }
    @media (max-width: 768px) {
      padding: 140px 60px;
      height: auto;
    }
  }

  @media only screen and (max-width: 1024px) {
    background-position: inherit;
    background-size: 100% 94%;
    .hero-container-image {
      display: none;
    }
  }

  .hero-container-image {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    @media only screen and (min-width: 1024px) {
      img {
        height: 500px;
      }
    }
    @media only screen and (min-width: 1441px) {
    }
  }

  /* Define the bounce animation */
  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }

  .hero-content {
    color: white;

    .award-badge {
      display: flex;
      padding: 4px 27px 4px 16px;
      border-radius: 50px;
      background: white;
      color: #0361f0;
      gap: 4px;
      align-items: center;
      width: fit-content;
      margin-bottom: 8px;

      .award-badge-image {
        width: 32px;
        height: 32px;
      }

      span {
        font-family: "Founders Grotesk";
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
      }
    }

    .hero-title {
      font-family: "Founders Grotesk";
      font-size: 58px;
      font-weight: 500;
      line-height: 66px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 16px;
      max-width: 652px;
      color: black;
    }

    .hero-subtitle {
      font-size: 17px;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
      max-width: 476px;
      font-family: inter;
      margin-bottom: 24px;
      color: black;
    }

    @media only screen and (max-width: 768px) {
      .hero-title {
        font-size: 40px;
        line-height: 44px;
      }

      .hero-subtitle {
        font-size: 16px;
        line-height: 25.76px;
      }
    }

    @media only screen and (max-width: 480px) {
      .hero-title {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 12px;
      }

      .hero-subtitle {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
      }

      .btn {
        padding: 8px 16px; /* Adjust padding for smaller screens */
        font-size: 14px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .hero-video {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: 38%;
    }

    // .hero-mask {
    //   background: linear-gradient(
    //     180deg,
    //     rgba(29, 29, 29, 0) 0%,
    //     rgba(29, 29, 29, 0.5) 79.14%
    //   );
    // }
    .hero-content-wrapper {
      padding: 47px 20px;
    }

    .hero-content {
      .award-badge {
        span {
          line-height: 1;
        }
      }

      .award-container {
        max-width: 167px;
        right: 20px;

        img {
          height: 167px;
        }
      }

      .hero-title {
        font-size: 40px;
        line-height: 44px;
      }

      .hero-subtitle {
        font-size: 16px;
        line-height: 25.76px;
      }
    }
  }
`;

const HowItWorks = styled.div`
  .content {
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
    padding: 76px 150px;

    @media only screen and (max-width: 1280px) {
        padding: 76px 60px;
    }

    @media only screen and (max-width: 1024px) {
      padding: 50px 20px;
    }
    }

    @media only screen and (max-width: 1024px) {
      background: rgba(3, 87, 238, 0.03);


      .how-it-works-title {
        text-align: center;
        font-size: 28px !important;
      }

      .how-it-works {
        font-size: 14px;
      }
    }

    .how-it-works-title {
      color: #000;
      font-family: Founders Grotesk;
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .how-it-works {
      color: var(--colors-neutral-black-100, #000);
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%; /* 20.8px */
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 40px;
      width: fit-content;
      background: var(--colors-primary-yellow-100, #fed75b);
    }
`;
const SliderMobile = styled.div`
  display: none;
  @media only screen and (max-width: 1024px) {
    display: block;
  }

  .content {
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
    padding: 76px 150px;
    display: flex;
    flex-direction: column;
    gap: 49px;

    @media only screen and (max-width: 1280px) {
        padding: 76px 60px;
    }

    @media only screen and (max-width: 1024px) {
      padding: 50px 20px;
    }
    }

    .how-it-works-container {
      align-items: center;
    }

    .how-it-works-title {
      color: #000;
      font-family: Founders Grotesk;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
      font-size: 28px;
    }

    .how-it-works {
      color: var(--colors-neutral-black-100, #000);
      text-align: center;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      line-height: 130%; /* 20.8px */
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 40px;
      width: fit-content;
      background: var(--colors-primary-yellow-100, #fed75b);
      font-size: 14px;
    }

  .title {
    color: #000;
    text-align: center;
    font-family: Founders Grotesk;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .feature-divider {
    border: 0.8px solid rgba(3, 87, 238);
    background: rgba(3, 87, 238);
    margin: 18px 0;
    position: relative;
    opacity: 0.3;

    &:after {
      position: absolute;
      bottom: -3px;
      left: 0;
      height: 6px;
      width: 6px;
      background: rgba(3, 87, 238);
      content: "";
      border-radius: 3px;
    }

    &:after {
      right: -3px;
      left: auto;
    }
  }
  .list-items {
    color: #555;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 18.2px */
    list-style: none;
  }
`;
const EaseContainer = styled.div`
  background: url(${(props) => props.src}), linear-gradient(180deg, rgba(242, 252, 255, 0.5) 14.41%, rgba(242, 252, 255, 0) 100%);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  .content {
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
    padding: 85px 149px 164px;
  }



  @keyframes rotateBackground {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media only screen and (max-width: 1280px) {
    .content {
      padding: 85px 60px 164px;
    }
  }

  @media only screen and (max-width: 1024px) {
    background: url(${(props) => props.src});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;

    .content {
      padding: 95px 20px 0;
    }
  }


  .ease-heading {
    margin: 0 auto;
    text-align: center;

    font-family: Founders Grotesk;
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    max-width: 723px;

    margin-bottom: 60px;
    color: black;
    // padding-top:118px;
  }

  .ease-content {
    font-family: inter;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 27px;
    font-weight: 500;
    line-height: 30px;
    font-size: 20px;
    margin-bottom: 60px;
    color: black;
  }

  .text-white-note {
    font-size: 10px;
    padding-top: 5px;
    line-height: 10px;
  }

  .ease-btn {
    justify-content: center;
    align-items: center;
    display: flex

  }

  .ease-btn-clr {

    padding: 12px 24px;
    background-color: rgba(254, 215, 91, 1);
    color: black;
    border-radius: 8px;
    gap: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;

    :hover {
      opacity: 0.8;
      transform: translateY(-4px);
      color: black;
      background-color: rgba(254, 215, 91, 1);
      border-color: rgba(254, 215, 91, 1);
    }

    svg {
      path {
        fill: black;
      }
    }
  }

  @media only screen and (max-width: 1024px) {

    .ease-content {
      display: flex;
      align-items: start;
      justify-content: center;
      flex-direction: column;
      gap: 14px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      margin-bottom: 40px;
      color: black;
    }

    .ease-heading {
      text-align: start;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 10px;
      color: rgba(9, 34, 86, 1);
      // padding-top:118px;
    }
`;
const FAQsContainer = styled.section`
  background-color: white;

  .content {
    position: relative;
    max-width: 1440px;
    margin: auto;
    padding: 64px 150px;
    @media only screen and (max-width: 1024px) {
      padding: 64px 60px;
    }

    .title {
      font-family: "Founders Grotesk";
      font-size: 48px;
      font-weight: 500;
      line-height: 57px;
      letter-spacing: 0em;
      text-align: center;
      max-width: 554px;
      margin: 0 auto 48px;
      color: rgba(0, 0, 0, 1);
    }

    .faqs-list {
      width: 100%;

      .faq-divider {
        width: 100%;
        height: 1px;
        background: rgba(3, 87, 238, 0.1);
        margin: 32px 0;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 48px 20px;

      .title {
        font-size: 48px;
        line-height: 37.76px;
        margin: 0 auto 48px;
        max-width: 288px;
      }

      .faqs-list {
      }
    }
  }
`;

const FAQItemConatainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;

  .toggle-button {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  .text-section {
    .title {
      max-width: 895px;
      font-family: "Founders Grotesk";
      font-size: 28px;
      font-weight: 500;
      line-height: 33px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(0, 0, 0, 1);
      margin-bottom: 0px;
      cursor: pointer;
    }

    .description {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 0px;
      display: none;

      &.open {
        display: block;
        margin-top: 16px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    gap: 26px;

    .text-section {
    }
  }
`;
